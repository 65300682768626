@import '../../base.scss';

.modal{
    position: relative;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background;
    box-sizing: border-box;
    padding: 30px;
    font-family:  "Montserrat", "Arial ", sans-serif;
    color: $white;
    z-index: 20;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid $title;
}

.title{
    @include title;
    color: $white;
}

.text{
    font-weight: 600;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    color: $green;
    margin: 15px 0 0;
}

.button{
    @include button
}


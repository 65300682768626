$white: #FFF;
$black: #252525;
$background-header:rgba(#1B1B1B, .8);
$background-footer: #FFFFFF1A;
$background: #1B1B1B;
$title: #838383;
$red:#CD2122; 
$text-box: #898989;
$green: #C8FF70;

@keyframes pfeil {
    0%{
        transform: translateX(-10px);
    }
    100%{
        transform: translateX(5px);
        opacity: 1;
    }
}


@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 50px 20px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 40px 10px;
    }

    @media(max-width: 425px){
        padding: 30px 10px;
    }
}

@mixin box-column {
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin title {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.2;
    text-align: center;
    color: $white;
    margin: 0;

    @media(max-width: 1020px){
        font-size: 30px;
    }

    @media(max-width: 768px){
            font-size: 26px;
        }

        & span {
            color: $green;
        }
}

@mixin text {
    color: $white;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;

    @media(max-width: 768px){
        font-size: 16px;
}
}

@mixin button{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 0;
    color: $black;
    width: 250px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 8px;
    background: $green;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
        box-shadow: 0px 0px 16px 8px rgba($green, 0.25), 0px 0px 8px 8px rgba($green, 0.25);
    }

    @media(max-width: 768px){
        width: 200px;
        height: 40px;
    }
}

@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;
    overflow: hidden;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}




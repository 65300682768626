@import '../../base.scss';

.item{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 30px;
}

.item_title{
    font-size: 22px;
    line-height: 1.2;
    color: $text-box;
    margin: 0 0 20px;
    text-align: center;
}

.item_box{
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    overflow: hidden;
}

.box_image{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image{
    width: 100%;
    margin-bottom: 10px;
    aspect-ratio: 4/3;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
    }
}

.subtitle{
    font-size: 22px;
    line-height: 1.2;
    color: $white;
    margin: 0;
    text-align: center;
    max-width: 450px;
}

.subtitle_green{
    font-size: 22px;
    line-height: 1.2;
    color: $green;
    margin: 0;
    text-align: center;
    max-width: 450px;
}

@media(max-width: 920px){

    .item_box{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    .item_title{
        font-size: 22px;
    }

    .subtitle{
        font-size: 20px;
    }
    
    .subtitle_green{
        font-size: 20px;
    }
}
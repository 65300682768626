@import '../../base.scss';

.main{
    @include main-column;
    overflow: hidden;
}

.box{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title
}

.list{
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
}

@media(max-width: 768px){
    .list{
        padding: 0 10px;
    }
}  


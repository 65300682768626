@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column
}

.title{
    @include title;
    margin-bottom: 30px;
    
}

.box_info{
    max-width: 1100px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-footer;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 8px;
}

.subtitle{
    font-size: 28px;
    line-height: 1.2;
    color: $white;
    margin: 0;
}

.text{
    @include text;
    margin-bottom: 30px
}

.button{
    @include button;
    margin: auto 0 0;
}

@media(max-width: 900px){
    .item{
        padding: 20px;
    }

    .subtitle{
        font-size: 24px;
    }
}

@media(max-width: 768px){
    .subtitle{
        font-size: 24px;
    }
}
@import '../../base.scss';


@keyframes arrow-down {
    0%{
        transform: rotate(45deg) translate(0, 0);
    }
    50%{
        transform: rotate(45deg) translate(15px, 15px);
    }
    100%{
        transform: rotate(45deg) translate(0, 0);
    }
}


    .main {
        max-width: 1440px;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, 0);
    }

.container{
    box-sizing: border-box;
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.item {
    color: $white;
    background-image: url(../../image/InitialPage/img_5.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
}

.title{
    max-width: 900px;
    font-size: 56px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    position: relative;
}

.line{
    background: $green;
    height: 3px;
    width: 50px;
    margin: 30px 0 20px; 
}

.text{
    font-size: 26px;
    line-height: 1.4;
    font-weight: 500;
    text-align: left;
    margin: 0;
    color: $white;
}

.button{
    background-color: $green;
    text-transform: uppercase;
    color: $black;
    border: none;
    width: 270px;
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 20px;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: all .3s ease-out;
        box-shadow: 0px 0px 16px 8px rgba($green, 0.25), 0px 0px 8px 8px rgba($green, 0.25);
    }
}

@media(max-width: 1020px){

    .container{
        padding: 0;
        width: 95%;
    }

    .title{
        max-width: 780px;
        font-size: 46px;
    }

    
    .text{
        font-size: 20px;
    }

}

@media(max-width: 768px){
    .main {
        padding: 0 10px;
    }

    .overlay{
        align-items: flex-start;
    }

    .item {
        color: $white;
        background-image: url(../../image/InitialPage/img_3.png);
        background-position: center center;
    }

    .container{
        padding: 100px 0 0;
        height: 100vh;
    }

    .title{
        font-size: 36px;
        max-width: 600px;
    }

    .line{
        width: 40px;
        margin: 20px 0 10px; 
    }
    
    .text{
        font-size: 17px;
    }

    .button{
        max-width: 100%;
        width: 100%;
        margin: auto auto 100px;
    }
}

@media(max-width: 600px){

    .title{
        max-width: 470px;
        font-size: 33px;
    }
}

@media(max-width: 500px){

    .title{
        max-width: 400px;
        font-size: 27px;
    }

}


@media(max-width: 425px){
    .main {
        padding: 0 10px;
    }

    .title{
        max-width: 320px;
        font-size: 24px;
    }

    .line{
        width: 30px;
        margin: 10px 0 5px; 
    }
}

@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    padding: 5px;
    overflow: hidden;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: $background-footer;
    overflow: hidden;
}

.box_image{
    width: 100%;
}

.image{
    width: 100%;
    aspect-ratio: 3/2;
}

.box_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.subtitle{
    font-size: 28px;
    line-height: 1.2;
    color: $white;
    margin: 0;
    text-align: center;
    max-width: 450px;
}

.item_text{
    font-size: 36px;
    line-height: 1.2;
    color: $text-box;
    font-weight: 700;
    margin: 20px 0 0;
}

.button{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 0;
    color: $black;
    max-width: 100%;
    width: 100%;
    height: 50px;
    margin: 20px 0 0;
    border-radius: 8px;
    background: $green;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
        box-shadow: 0px 0px 16px 8px rgba($green, 0.25), 0px 0px 8px 8px rgba($green, 0.25);
    }
}

@media(max-width: 920px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .subtitle{
        font-size: 24px;
    }
}

@media(max-width: 768px){
    .subtitle{
        font-size: 24px;
    }
}
@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding-bottom: 30px
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    @include list;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    border-radius: 8px;
    background-color: $background-footer;
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $green;
    box-shadow: 0px 0px 8px 4px rgba($green, 0.25), 0px 0px 4px 4px rgba($green, 0.25);
}


.icon{
    width: 50px;
    height: 100%;
}

.item_title{
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    color: $white;
    margin: 20px 0 0;
}

.item_text{
    color: $white;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0 0;
}


.button{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 0;
    color: $black;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 8px;
    background: $green;
    z-index: 1;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
        box-shadow: 0px 0px 16px 8px rgba($green, 0.25), 0px 0px 8px 8px rgba($green, 0.25);
    }
}

.image{
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 1%;
}

@media(max-width: 1020px){
    button{
        max-width: 250px;
    }

    .item_title{
        font-size: 22px;
    }
}

@media(max-width: 768px){

    .image{
        width: 250px;
    }
}
@import '../../base.scss';

.hint {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;

    &:last-child{
        border-bottom: none;
    }
}

.box {
    background-color: $background-footer;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    &:hover {
        
        & h3{
            color: $green;
        }
        cursor: pointer;
        transform: .3s all
    }
}

.box_active{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cap{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 10px;
    border-top: 2px solid $background-footer;
}

.cap_grey{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 10px;
    border-top: 2px solid $background-footer;
}

.cap_text{
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
    color: $title;
    text-align: left;
}

.service{
    max-width: 70%;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    margin: 0;
    color: $white;
    text-align: left;
}

.price{
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    margin: 0 0 0 10px;
    color: $green;
    text-align: left;
}

.hint_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hint_circle{
    box-sizing: border-box;
    min-width: 10px;
    height: 10px;
    margin-right: 20px;
    background-color: $green;
    border-radius: 50%;
}

.title {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0;
    color: $white;
    text-align: left;
}

.hint_image{
    width: 14px;
    height: 11px;
    margin-left: 10px;
    transition: all 1s ease;
}

.hint_active{
    width: 14px;
    height: 11px;
    transition: all 1s ease;
    transform: rotate(-180deg);
}

@media(max-width: 768px){
    .title {
        font-size: 16px;
    }

    .cap_text{
        font-size: 14px;
    }

    .service{
        font-size: 12px;
    }
    
    .price{
        font-size: 12px;
        font-weight: 600;
    }
}  
@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    background-color: $background-footer;
}

.container{
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 20px;
}

.box_info{
    box-sizing: border-box;
}


.title{
    @include title;
    text-align: left;
}

.text{
    color: $white;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0 0;
}

.image{
    width: 40%;
    margin: 0 50px 0 0;
}

.list{
    max-width: 110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 0;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 50px;
    height: 50px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #64a9dc;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link{
    width: 50px;
    height: 50px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #65bc54;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 30px;
    height: 30px;
}

@media(max-width: 900px){
    .list{
        margin: 20px 0 0;
        max-width: 90px;
    }

    .link_telegram{
        width: 40px;
        height: 40px;
    }
    
    .link{
        width: 40px;
        height: 40px;
    }
    
    .image_icon{
        width: 25px;
        height: 25px;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 40px 10px;
    }

    .title{
        text-align: center;
    }

    .text{
        font-size: 16px;
        text-align: center;
    }

    .image{
        width: 90%;
        margin: 0;
    }
    
    .box_info{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

}

@media(max-width: 425px){

    .container{
    padding: 30px 10px;
}
}